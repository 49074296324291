import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

import Layout2 from "../../components/layout/layout2";
import { pastQueens } from "../../constants/pastqueens";

import { FaInstagram, FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";

import SEO from "../../components/seo";

const Queens = () => {
  const data = useStaticQuery(graphql`
    {
      SWAContent {
        queens {
          description
          fbLink
          igLink
          linkedinLink
          name
          queenGown
          queenProfile
          status
          title
          titleDate
          ytLink
        }
      }
    }
  `);
  const [queens, setQueens] = useState(data.SWAContent.queens);
  const [past, setPast] = useState(pastQueens);
  // if (queens) {
  //   console.log("queens: ", queens);
  // }
  // if (past) {
  //   console.log("past queens: ", past);
  // }

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout2>
        <SEO
          title="Past Queens - Miss Singapore Pageant International"
          description="Miss Singapore International will represent Singapore on an International platform with 84 contestants around the world. The exchanges and exposure aim to bring forth more women to anchor a role in the society with a charitable approach, grace, integrity and honour."
        />
        <div className="relative mx-5 rounded-lg sm:mx-15 md:mx-5">
          <div className="absolute inset-0">
            {/* <img
              className="object-cover w-full h-full"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
              alt=""
            /> */}
            {/* <div
              className="absolute inset-0 bg-purple-900 mix-blend-multiply"
              aria-hidden="true"
            ></div> */}
          </div>
          <div className="relative px-4 py-24 mx-auto -mb-24 max-w-7xl sm:py-32 sm:px-6 lg:px-1">
            <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl text-swa-1">
              Past Queens
            </h1>
          </div>
        </div>
        <div className="">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 ">
            <div className="space-y-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:space-y-0">
              <div className="lg:col-span-2">
                <div
                  role="list"
                  className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
                >
                  
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1693120188/queenProfile/ggeezmquybig7chj7rnq.png"
                            alt="Scarlett Sim"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Scarlett Sim</h2>
                              <p className="font-bold text-swa-1">
                                Miss Singapore International
                                2022
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              My name is Scarlett Sim, and I am a flight attendant who
                    creates an exquisite experience for passengers by providing
                    safety and personalized services. I build, connect, and
                    maintain positive relationships, always making the first
                    impression count and leaving the last impression that stays.
                    Outside of work, I enjoy reading books and going to the gym.<br /><br />
                    I believe that volunteering connects people from all walks
                    of life; a great way to meet new people with common
                    interests. Helping others kindles happiness, and seeing the
                    smiles on the elderly face warms my heart. It is a rewarding
                    feeling when one gives back to the community, which helps us
                    achieve greater life satisfaction
                              </div>
                            </div>
                    
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1693120490/queenProfile/ttr1ium8kuiemuewm3ad.png"
                            alt="Jiayi Sin"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Jiayi Sin</h2>
                              <p className="font-bold text-swa-1">
                                Miss Singapore Supranational
                                2022
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              
                    My name is Jiayi Sin, and I am a fresh graduate with a
                    Bachelor’s Degree in Marketing. I am also currently a model
                    and I enjoy all things sports, especially badminton!
                    <br /><br />
                 
                    Growing up, my parents are strong believers of independence.
                    They encouraged me to start working part time at a young age
                    of 14 years old to earn money for the things that I want.
                    Going through this phase has taught me to be a
                    self-motivated and responsible person.
                    <br /><br />
                    I have also been taught the value of empathy, and it somehow
                    grew in me to be a person who became passionate about
                    serving the people around me. As such, I strongly believe
                    that it is important to give back to the community. I am
                    also blessed to be able to spend my growing up years with my
                    dear grandmother, and this special experience has helped me
                    better understand the intricacies of developing
                    relationships with elderlies. Knowing that my presence
                    provided much joy and comfort to the elderlies around me
                    brought smiles to my face, and reinforced my passion to give
                    back to the community.
                
                              </div>
                            </div>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635224098/queenProfile/qt6qqhjoxvkvnu8o91dt.jpg"
                            alt="Charlotte Chia"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Charlotte Lucille Chia</h2>
                              <p className="font-bold text-swa-1">
                                Miss Singapore International
                                2019
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I am a graduate with a degree in International and Global Studies. A lover of the arts, experiencing different cultures, and language. I am fluent in English and Chinese, and conversational in Korean and Japanese. Having lived abroad, I am confident in my ability of interacting with people of different cultures. Ambitious— I hope to one day become the first female Secretary-General of the United Nations. What drew me to join MSPI 2019 was the charity aspect of the pageant. Since then, I have been involved in volunteering and fundraising with SWA. I have also volunteered with the Youth Corps Singapore, and have recently began volunteering at a center enriching children with disabilities
                              </div>
                            </div>
                       
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635225189/queenProfile/mnvjokfgs4jqfzatng9l.jpg"
                            alt="Charlotte Chia"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Naomi Huth</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore Supranational 2019
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              <p>Miss Supranational 2019 in Poland</p><ul><li>Top 25</li><li>Best in Talent Award</li><li>Best Interview Award</li><li>Top 10 Influencer Finalist</li></ul><p>&nbsp;</p><p>As a firm believer that every small gesture makes a difference, I’m an adventurous, ambitious and positive soul, with the goal to raise the consciousness level amongst our people. Being a certified Yoga Instructor helps me spread positivity and body-mind-soul awareness to all age groups, allowing people to reconnect to themselves. In 2016, I was involved in a two-week social project in India, where I was painting an orphanage and raised funds to remodel and renovate the interior to bring comfort to their lives. I strive to help the less fortunate and under privileged people as much as I can through various charitable projects in the coming future. "</p>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635232135/queenProfile/jdnzjtdzzt5mei56lzmh.jpg"
                            alt="Eileen Feng"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Eileen Feng</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore International and and Miss International Asia 2018
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I’m currently pursuing my Masters in Architecture at the National University of Singapore, and I also hope to build a career in the modelling industry. I’m passionate about design and all things creative and innovative, and I look forward to build an all-rounded portfolio in the industry. I am highly extroverted, and I constantly strive to not only improve myself but also improve the lives of others around me. Being a part of SWA, I love working with kids and being infected with their unbridled energy and creativity. I am also invested in MSI’s vision to cheer on women and help them live their best life.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635232286/queenProfile/qsxjf16fa3gdf8esi9h2.jpg"
                            alt="Priyanka Annuncia"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Priyanka Annuncia</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore Supranational 2018
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I am a creative, confident and ambitious budding young entrepreneur. I have delved into many different careers because I don’t believe in limiting yourself but instead breaking out from your comfort zone and discovering your full potential. I am fortunate to be doing my job as a Personal Trainer, Public Speaking Coach and Proud Business Owner of a Body-Positive Movement. I am currently studying Business Law and Criminology a double major degree at Murdoch University something I have always been passionate about. I pride myself in my optimistic outlook in any situation as well as my excellent leadership skills and passion towards the work that I do. I am looking forward to elevating my experiences by breaking new grounds and impacting new lives!
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635232372/queenProfile/bczedhbvmwkdofwwvkkw.jpg"
                            alt="Nerrine Ng"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Nerrine Ng</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore Asia Pacific International 2018
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I pride myself on being cosmopolitan. Not your average leisure traveller, I attribute my fascination with globetrotting to being a Sociology graduate of the National University of Singapore; I have undertaken several solo backpacking adventures, embracing every interaction with the locals as an opportunity to better understand societies nuanced with a wealth of history. A former cabin crew of Singapore Airlines, I versatility currently place me as a business development manager in the tourism industry. Outspoken, I endeavour to empower women and mentor teenagers in need of guidance. Although a classically-trained pianist, I fancy a vast genre of music— from Mandopop to EDM; I count fitness, healthcare and blockchain technology as some of my keen interests. A true blue Singaporean, you will sometimes spot me patiently queuing for the latest food fad.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635232453/queenProfile/c6oer2q8bzpeumqrrsqe.jpg"
                            alt="Kylie Yeo"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Kylie Yeo</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore International 2017
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I am a highly creative individual with an Honours degree in Fine Arts from Nanyang Technological University's School of Art, Design and Media. Currently in the field of specialising in social media and content creation, I spend my free time pursuing my interests in music, illustration and embroidery. Aside my passion for the arts, children and animals hold a special place in my heart and I strive towards making a difference in their lives.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635232731/queenProfile/kay3gcl2wcqnkc75zqrf.jpg"
                            alt="Lynn Teo"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Lynn Teo</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore Supranational 2017
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I am a Procurement Manager for Marketing and Business Services for the fast-moving consumer goods industry. Upon graduating from Singapore Management University, I started my career as a Management Trainee in the Procurement field and have also worked in Laos, the Netherlands and New Zealand.<br /><br />My working experience moulded me into an adaptable and resilient individual and developed highly valuable skills such as presentation, analysis and relationship management. I was also lucky to have amazing mentors along the way, and that allowed me to fast track my career progression.<br /><br />My next milestone is to build a business of my own as well as a platform to help the low-income group in Singapore.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635232879/queenProfile/jwz8h3q9rnj24pzasxkj.jpg"
                            alt="Lynette Chua"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Lynette Chua</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore Asia Pacific 2017
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I am a bubbly, energetic yet matured individual who strives to live a fulfilling and impactful life. I am pursuing an honours degree as a full-time student at Nanyang Technological University, and I am also modelling part-time with NOW Model Management. When I graduate, I aim to begin a purposeful career that develops me individually while providing me with the opportunities to push boundaries and pursue breakthroughs with the potential to make significant contributions to society.
                              <br /><br />
                              On the personal side, I enjoy exercising, travelling, spending quality time and having good, meaningful conversations with my family, partner and friends. I enjoy socialising and am thankful that by joining the pageant and modelling over the years, I have honed my communication skills. Conversely, as outgoing as I may seem, I do appreciate my alone time as well, where I can de-stress, recharge and prepare to make tomorrow a better one
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635232935/queenProfile/xy19batbqbkd5hp99ca6.jpg"
                            alt="Janice Sia"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Janice Sia</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore Tourism Queen of the Year International 2017
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              Beauty pageants were something I would never think of joining, but I have no regrets and I’m very grateful for the entire program. Through this program that MSPI has set up, I made many close friends, both in the local and overseas competition. And on top of that, I’ve also gained many learning experiences from all the trainers and mentors in MSPI (SWA). Taking part in pageant requires a lot more hard work than you think. We had to put in extra hours of practice out of training time, doing things like practising In heels, catwalk, poses, dance practice and more. It was especially tough and challenging for someone with no background like me, but very fulfilling. Prior to this competition, I was a timid girl with low self-esteem and confidence. I dare not present myself in front of the audiences and do not bother to project a good image of myself. I am amazed at how this pageant experience has changed me. One big change in my life was learning how to put on makeup and styling my hair. I’ve grown tremendously in my confidence and in carrying myself. Through the many events and activities in the pageant journey, I’ve learnt how to adapt to the environment easily. I am proud to say that I am now a girl who has "graduated" from this program and am ready to take on even bigger challenges in the future.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635233019/queenProfile/xhjv6vnkekfp988rphlu.jpg"
                            alt="Wang Huiqi"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Wang Huiqi</h2>
                              <p className="font-bold text-swa-1">
                                
Wang Huiqi
Miss Singapore International 2016
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I am a mature, driven, and hardworking individual who always strives to achieve the highest standard possible at any given task. I am a fresh graduate from the National University of Singapore and am currently working in the Real Estate Industry. Real Estate has always been my passion because of how impactful it is to people's everyday life. It gives me great satisfaction to see how I can create something out of nothing but just bricks and mortar, which will, in turn, create experiences and memories for the users. Growing up as an independent child, I've worked for many different types of part-time jobs (mainly sales). These experiences have trained me to be resourceful and dynamic people's person. The perseverance to be self-sufficient since 18-year-old and courage to step out of my comfort zone has also shaped who I am today. These two values are values that I hold with high regards as they continue to guide me through any challenges or opportunities I'll face in the future. I believe that community service is a lifelong work for everybody. Therefore, I aim to become the best of myself so that I can, in return, serve the community to the best of my ability.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635233103/queenProfile/jj1ugpu8maajoqukwls6.jpg"
                            alt="Chloe Xu"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Chloe Xu</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore Supranational 2016
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I am a strong advocate of SWA’s motto, Beauty with a Heart and Purpose. A beauty pageant should not only focus on external beauty but their service to the community, being a role model and making a difference in other people’ lives. I graduated from the University of California, San Diego with a degree in Media and Communications, which led to my endeavours in the fin-tech industry followed by the oil industry. I take pride in my beliefs and experiences to help inspire and motivate people. After the pageant, I initiated the MSPI Alumni group to reconnect all of the past beauty queens, which aimed to reinforce leadership, friendship and service to the community with our network. Being part of SWA has been a great platform to nurture future women leaders of tomorrow, and I look forward to contributing more to society.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635233190/queenProfile/n0fth3rmncpulq0v2ew6.jpg"
                            alt="Ariel Xu"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Ariel Xu</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore Asia Pacific 2016
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I am out-going and independent, with a great passion for travel as well as food. I graduated with a Bachelor of Science in Hospitality Management, currently building a professional career in the hospitality and customer service industry. I possess a positive attitude, dedication, and a keen interest in learning new things. Being able to multi-task and maintain professionalism while working under pressure are also my strengths. My ability to build rapport with customers paired with my strong communication skills, has enabled me to excel in my professional career. These skills definitely come in handy whenever I reach out to the needy. Singapore Women’s Association (SWA) has been a very supportive platform for me. SWA nurtured me into a beautiful and confident woman, and most importantly, to stay humble and true to myself. I am truly blessed to be part of the SWA family and thankful to have fulfilling and memorable pageant as well as volunteer experiences. I am deeply inspired by SWA’s volunteerism spirit – ‘giving without expecting anything in return’. I aim to give back to the community and help the needy by staying involved in community projects, especially through the platform established by SWA.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635233243/queenProfile/jpospnderk69ptaumre4.jpg"
                            alt="Kaitlyn Xu"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Kaitlyn Xu</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore Tourism Queen of the Year International 2016
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              A little background about myself, I graduated from the University of California, Los Angeles (UCLA) and started my career in Singapore Airlines head office. I'm a responsible, motivated and friendly individual with a great passion for volunteerism. I believe that a beauty queen should be a woman of compassion and leadership, to provide service for the community and to build more leaders around her. It was such a wonderful and enriching pageant journey, through all the hard work and bonding with the other girls, in Singapore and internationally. With SWA, I hope to create more meaningful experiences and shape myself and others to be a better person each day.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635233311/queenProfile/k9pytvibcixj0ttatij5.jpg"
                            alt="
                            Roxanne Zhang"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>
Roxanne Zhang</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore International 2015
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              Graduated from Beijing Central Drama Academy Performing Arts Advance Course in 2017; Bachelor Degree in Business Marketing; and Diploma in Marine and Offshore Technology in Nava Arch Design. A Corporate speaker, a signed artist in Beijing and also an actress and model in Singapore.<br /><br />

As a modern woman, I’m a risk-taker. About 2 years ago, I dropped everything in Singapore: family, friends, career and left for China, Beijing to chase my dream. I was given the opportunity and signed to an artist agency. Most of my friends would say that I’m crazy, but I will always say, don’t live life with regrets, even though there is only 1% of the chance of being successful. And that’s why I also started my own company, Modesugar, a fashion digital magazine.

​<br /><br />

This is my 4th year being part of Singapore women’s association and the greatest part of being in this community is how I get to inspire and help young women every year when they join us in the journey of our beauty pageant. Singapore women’s association, in short SWA strongly believe in helping the elderly and this is something that I truly believe in too. We have regular visits to Ren Ci Hospital and also Lion’s home to visit the elderly. I’m very close with my grandparents and they were a huge influence on my life. Thus, being able to bring smiles to the elderly warms my heart and gives me the drive to wake up early on a Sunday when I have to.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635233410/queenProfile/c4mcosg61wl1vlnl8bii.jpg"
                            alt="Sharon Nadine"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Sharon Nadine</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore Supranational 2015
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I am a highly motivated, positive, and independent individual who is currently a Complex Leader in the airline industry. As a young leader, I am able to lead my team on flights, encouraging them to handle difficult situations, and lead by example to be strong and resilient. Naturally, I have a desire to travel and see the world as well. This allows me to share new experiences with the future leaders that MSPI will create on how to be a leader and how to embrace different cultures of the world.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635233496/queenProfile/k7wcb5tajw7gjfqkiagh.png"
                            alt="Kyla Tan"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Kyla Tan</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore International 2010
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I'm often quoted as "The queen who has never left".<br /><br />

I celebrated my twenteens with SWA, from my graduation from Nanyang Technological University (NTU) with an Honours degree in Chemistry & Biological Chemistry, to getting certified as a Style Coach, and becoming a Sales Manager in the Healthcare sector.<br /><br />

Through these years, I am heartened to witness and support the amazing transformations of queens and finalists, each with laudable qualities, shining in their own ways. They are testimonies to MSPI being a meaningful platform to develop aspiring young female leaders with good heart and purpose to achieve great things in the global arena.<br /><br />

My greatest privilege is to have the opportunity to learn and grow alongside many inspiring ladies in SWA. SWA is like family to me. And I extend my warmest welcome to you, our next queen.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635233555/queenProfile/oyaky1dbgl44vyqchluu.jpg"
                            alt="Sherry Ng"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Sherry Ng</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore International 2004
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              Formerly a journalist in Singapore’s Chinese media whose stories won awards, I switched into private banking 13 years ago and have a wealth of experience with China, Indonesia and Middle Eastern clients, winning awards & accolades in banks I was with — Standard Chartered Private Bank, Merrill Lynch, JP Morgan & Bank of Singapore. Started my own business in asset management 3 years ago, coming out from the sheltered environment of banking institutions opened my eyes to the reality of a society where I built my own brand name with no particular bank as my employer /“backer”. The experience made me more mature & discerning, and I am thankful for the people who truly got my back — family, friends and wonderful clients who believe in me and are now my mentors, client and Friend.<br /><br />

 

Beauty lies in character — strength, passion & love. I do not bend myself to fit into others’ moulds but always be myself, and a better myself with each day. Time will show others who you truly are and allow you to discern and build relationships that withstand trials and time. That to me is the true meaning of beauty.<br /><br />

 

Motto in life: Even if the world has let me down, my smile can still light up someone’s world, so I will not lose faith. Keep smiling!
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635233630/queenProfile/r7byqvo3zzsqtb12lzah.png"
                            alt="Janice Koh"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Janice Koh</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore International 1999
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              Graduated with a Masters in Business Administration from National University of Singapore Business School, I was with an MNC in the management consulting industry for 13 years before moving recently to become the Head of Corporate Development for a leading (NYSE listed) Chinese wealth and asset management company. As part of the group’s internationalization strategy, I am responsible for setting up and operationalizing their Singapore office.<br /><br />

​

SWA holds a very special place in my heart. Not only was I welcomed into a family surrounded by a group of warm-hearted and dedicated women who have selflessly volunteer their time to help and support the underprivileged, it has also provided a platform to women like me where its mission was to provide a “risk free” environment for young women to develop leadership skills by empowering us to realize our potentials and to pursue our dreams while inculcating in us the importance and values of giving back to society.<br /><br />

I was very fortunate to have been given the opportunity to learn and share my journey and experiences accumulated in SWA with young women and to help them discover their talents and strengths while opening their minds to new and greater possibilities in life …. to grow stronger, be inspired and to be an inspiration to others in which I personally had benefit from the platform that SWA has provided me with.<br /><br />

​

Only SWA truly lives up to the spirit of “Beauty with a Heart and Purpose”.

And here’s a quote that I personally live by which is “Winners never quit, and quitters never win”
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" sm:py-8">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-3 md:w-1/3">
                          <img
                            className="object-cover mx-auto rounded-full w-52 h-52 md:w-36 md:h-36 lg:w-48 lg:h-48"
                            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1635233708/queenProfile/fw7o2xusaixuhbbokwlg.jpg"
                            alt="Lynette Lee"
                          />
                        </div>
                        <div className="md:w-2/3">
                          <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                              <h2>Lynette Lee</h2>
                              <p className="font-bold text-swa-1">
                              Miss Singapore International 1995
                              </p>
                            </div>
                            <div className="text-lg">
                              <div className="">
                              I was a lucky contender being selected as Miss international in 1995 and picked up a couple of subtitles along the way. It was an enriching environment which has exposed my horizons beyond my comfort zone. All these were made possible by the wonderful committee and the hospitable team in the contest in Japan. The friendships were formed before the era of technological advancements and it certainly has made an impression on a young girl then.<br /><br />

 

I am currently in Fintech space with a Chinese conglomerate in strategic business development consultancy and have spent my earlier years running my own businesses and sold 2 businesses which allowed me a great travelling sabbatical break amidst completing my masters. I am blessed by the plentiful opportunities in life and the wonderful people I have met along my life journey.<br /><br />

 

My motto in life: Look deeper, further and beyond. Our time is an investment which is more precious than anything. Use it wisely as it can never be recovered.
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
     
                  
                  
                  <section>
                    <div className="grid grid-cols-1 gap-4 my-10 sm:grid-cols-2">
                      {past.map(queen => {
                        return (
                          <div
                            key={queen.name}
                            className="relative flex items-center px-6 py-5 space-x-3 border border-gray-200 rounded-lg shadow-sm hover:border-swa-3 "
                          >
                            {/* <div className="flex-shrink-0">
                              <img
                                className="w-10 h-10 rounded-full"
                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                              />
                            </div> */}
                            <div className="flex-1 min-w-0">
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              ></span>
                              <p className="text-base font-bold ">
                                {queen.name}
                              </p>
                              <p className="text-sm truncate text-swa-1">
                                {queen.title}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout2>
    </>
  );
};

export default Queens;
