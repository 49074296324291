export const pastQueens = [
  {
    id: 2023,
    name: "Chavelle Chong",
    title: "Miss Singapore International 2023",
  },
  {
    id: 20221,
    name: "Scarlett Sim",
    title: "Miss Singapore International 2022",
  },
  {
    id: 20222,
    name: "Jiayi Sin",
    title: "Miss Singapore Supranational 2022",
  },
  {
    id: 1,
    name: "Eileen Feng",
    title: "Miss Singapore International 2018",
  },
  {
    id: 2,
    name: "Priyanka Annuncia",
    title: "Miss Singapore Supranational 2018",
  },
  {
    id: 21,
    name: "Nerrine Ng",
    title: "Miss Singapore Asia Pacific International 2018",
  },
  {
    id: 3,
    name: "Kylie Yeo",
    title: "Miss Singapore International 2017",
  },
  {
    id: 31,
    name: "Lynn Teo",
    title: "Miss Singapore Supranational 2017",
  },
  {
    id: 4,
    name: "Lynette Chua",
    title: "Miss Asia Pacific International 2017",
  },
  {
    id: 32,
    name: "Janice Sia",
    title: "Miss Singapore Tourism International 2017",
  },
  {
    id: 5,
    name: "Wang Hui Qi",
    title: "Miss Singapore International 2016",
  },
  {
    id: 33,
    name: "Chloe Xu",
    title: "Miss Singapore Supranational 2016",
  },
  {
    id: 34,
    name: "Kaitlyn Xu",
    title: "Miss Singapore Tourism International 2016",
  },
  {
    id: 6,
    name: "Ariel Xu",
    title: "Miss Asia Pacific International 2016",
  },
  {
    id: 7,
    name: "Roxanne Zhang",
    title: "Miss Singapore International 2015",
  },
  {
    id: 35,
    name: "Sharon Nadine Wee",
    title: "Miss Singapore Supranational 2015",
  },

  {
    id: 8,
    name: "Vanessa Sim Zhen Wei",
    title: "Miss Singapore International 2014",
  },
  {
    id: 36,
    name: "Chew Jia Min",
    title: "Miss Singapore International 2013",
  },
  {
    id: 9,
    name: "Leong Ying Mae",
    title: "Miss Singapore International 2012",
  },
  {
    id: 37,
    name: "Stella Kae Sze Yun",
    title: "Miss Singapore International 2011",
  },
  {
    id: 10,
    name: "Tan Yong Ying",
    title: "Miss Singapore International 2010",
  },
  {
    id: 38,
    name: "Annabelle Liang",
    title: "Miss Singapore International 2009",
  },
  {
    id: 11,
    name: "Tok Wee Ee",
    title: "Miss Singapore International 2008",
  },

  {
    id: 39,
    name: "Christabel Tsai",
    title: "Miss Singapore International 2007",
  },
  {
    id: 12,
    name: "Genecia Luo",
    title: "Miss Singapore International 2006",
  },
  {
    id: 40,
    name: "Catherine Tan",
    title: "Miss Singapore International 2005",
  },
  {
    id: 13,
    name: "Sherry Ng",
    title: "Miss Singapore International 2004",
  },
  {
    id: 41,
    name: "Berlin Koh",
    title: "Miss Singapore International 2003",
  },
  {
    id: 14,
    name: "Marie Wong",
    title: "Miss Singapore International 2002",
  },
  {
    id: 42,
    name: "Juley Abdullah",
    title: "Miss Singapore International 2001",
  },
  {
    id: 15,
    name: "Lorraine Koo",
    title: "Miss Singapore International 2000",
  },

  {
    id: 43,
    name: "Janice Koh",
    title: "Miss Singapore International 1999",
  },
  {
    id: 16,
    name: "Suhda Menon",
    title: "Miss Singapore International 1998",
  },
  {
    id: 44,
    name: "Joey Chan",
    title: "Miss Singapore International 1997",
  },
  {
    id: 17,
    name: "Carel Low",
    title: "Miss Singapore International 1996",
  },
  {
    id: 45,
    name: "Lynette Lee",
    title: "Miss Singapore International 1995",
  },
  {
    id: 18,
    name: "Jocelyn Teo",
    title: "Miss Singapore International 1994",
  },

  {
    id: 46,
    name: "Teri Tan",
    title: "Miss Singapore International 1993",
  },
  {
    id: 19,
    name: "Lily Goh",
    title: "Miss Singapore International 1992",
  },
  {
    id: 47,
    name: "Audrey Tan",
    title: "Miss Singapore International 1991",
  },
  {
    id: 20,
    name: "Gennie Wong",
    title: "Miss Singapore International 1990",
  },
  {
    id: 47,
    name: "Angeline Lip",
    title: "Miss Singapore International 1988",
  },
  {
    id: 21,
    name: "Majorie Tan",
    title: "Miss Singapore International 1987",
  },

  {
    id: 48,
    name: "Teo Ser Lee",
    title: "Miss Singapore International 1986",
  },
  {
    id: 22,
    name: "Audrey Chua",
    title: "Miss Singapore International 1985",
  },

  {
    id: 49,
    name: "Wong Ee Leng",
    title: "Miss Singapore International 1984",
  },
  {
    id: 23,
    name: "Elsie Oh",
    title: "Miss Singapore Asia Quest 1984",
  },
  {
    id: 50,
    name: "Patricia Ngow",
    title: "Miss Singapore International 1983",
  },
  {
    id: 24,
    name: "Angela Tan",
    title: "Miss Singapore International 1982",
  },
  {
    id: 51,
    name: "Shanaz A Ghani",
    title: "Miss Singapore International 1981",
  },
  {
    id: 25,
    name: "Wendy Liong",
    title: "Miss Singapore Young International 1981",
  },

  {
    id: 52,
    name: "Adda Pang",
    title: "Miss Singapore World 1980",
  },
  {
    id: 26,
    name: "Lajiah Sharma",
    title: "Miss Singapore Asia 1980",
  },

  {
    id: 53,
    name: "Violet Lee",
    title: "Miss Singapore World 1979",
  },
  {
    id: 27,
    name: "Rosie Tan",
    title: "Miss Singapore World 1978",
  },
  {
    id: 54,
    name: "Veronica Loudes",
    title: "Miss Singapore World 1977",
  },
  {
    id: 28,
    name: "Pauline Cheong",
    title: "Miss Singapore International 1976",
  },
  {
    id: 29,
    name: "Jacqueline Stuart",
    title: "Miss Singapore Asia 1975",
  },

  {
    id: 55,
    name: "Maggie Sim",
    title: "Miss Singapore World 1975",
  },
];
